<template>
  <div class="pt-3 pb-0 px-1">
    <v-row class="mt-5 mb-2 d-flex justify-end">
      <v-col cols="6" sm="2" md="2" offset-md="1" offset-sm="1" class="d-flex justify-end align-center py-0">
        <v-switch class="mt-0 pt-0" dense v-model="opcionSolo" label="Solo yo" color="primary" hide-details></v-switch>
      </v-col>
      <v-col cols="12" sm="2" md="2" class="d-flex align-center py-0">
        <v-select
          dense
          hide-details
          v-model="anio"
          :items="listAnios"
          @change="cambioAnio"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Año"
        ></v-select>
      </v-col>
      <!-- append-outer-icon="mdi-find-replace" @click:append-outer="listarYCargarAreas" -->
      <v-col cols="12" sm="3" md="3" class="d-flex align-center py-0">
        <v-select
          dense
          hide-details
          v-model="semana"
          :items="semanas"
          no-data-text="No se encontró información"
          item-text="text"
          item-value="value"
          label="Semana"
          multiple
          @blur="listarYCargarAreas"
        >
        </v-select>
      </v-col>
      <v-col cols="12" sm="3" md="3" class="d-flex align-center py-0">
        <v-select
          dense
          hide-details
          multiple
          v-model="areas"
          :items="itemsAreas"
          @blur="initialize"
          no-data-text="No se encontró información"
          :return-object="false"
          item-text="text"
          item-value="value"
          label="Área"
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="selectAllAreas" @mousedown.prevent>
              <v-list-item-action>
                <v-icon color="primary">{{ iconCheckboxAllAreas }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> TODOS </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template v-slot:selection="{ item, index }">
            <template v-if="areas.length == itemsAreas.length">
              <span v-if="index === 0">TODOS</span>
            </template>
            <template v-else>
              <span v-if="index > 0">,</span><span>{{ item.value }}</span>
            </template>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="1" md="1" v-auth-acl="'gh-okr-mat_seg-export'" class="d-flex align-center justify-end py-0">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" fab dark small @click="exportarbase" v-bind="attrs" v-on="on">
              <v-icon dark>mdi-cloud-download</v-icon>
            </v-btn>
          </template>
          <span>Exportar BD</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :height="height"
      :items="okrItems"
      class="border altura"
      multi-sort
      :mobile-breakpoint="0"
      :items-per-page="-1"
      no-results-text="No se encontraron datos"
      no-data-text="No se encontraron datos"
      :search="search"
      item-class="class"
      :fixed-header="true"
    >
      <template v-slot:top>
        <div class="w-100 d-flex justify-space-between align-center pa-2">
          <div class="black--text">OKR'S</div>
          <v-divider class="mx-4" vertical></v-divider>
          <v-text-field
            v-model="search"
            dense
            append-icon="mdi-magnify"
            label="Buscar"
            class="mt-0 text-field-search-matriz"
            background-color="rgb(220, 220, 220, 0.16)"
            rounded
            single-line
            hide-details
          ></v-text-field>
        </div>
      </template>
      <template v-slot:[`item.realizado`]="{ item }">
        <v-chip small v-if="item.realizado == 'SI'" color="green" dark>
          <span>REALIZADO</span>
        </v-chip>
        <v-chip small v-if="item.realizado == 'NO'" color="red" dark>
          <span>NO REALIZADO</span>
        </v-chip>
      </template>
      <template v-slot:[`item.entregable`]="{ item }">
        <v-chip
          small
          v-if="
            (parseInt(item.indicador) === 1 && parseInt(item.subio) === 1) ||
              (parseInt(item.indicador) === 0 && parseInt(item.subio) === 1)
          "
          color="primary"
          dark
          @click="exportar(item)"
        >
          <v-icon color="white">mdi-cloud-download</v-icon>
          <span :class="['ml-3']">
            {{ item.nombreentregable }}
          </span>
        </v-chip>
        <v-chip
          small
          v-if="parseInt(item.indicador) === 1 && parseInt(item.subio) === 0"
          color="primary"
          dark
          @click="openDialogFile(item)"
        >
          <v-icon color="white">mdi-cloud-upload</v-icon>
        </v-chip>
        <v-chip small v-if="parseInt(item.indicador) === 0 && parseInt(item.subio) === 0" color="red" dark>
          <v-icon color="white">mdi-link-variant-off</v-icon>
          <span :class="['ml-3']">SIN ENTREGABLE</span>
        </v-chip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">OKR: {{ okrId }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <v-file-input
                  dense
                  hide-details
                  :rules="rules"
                  v-model="evidencia"
                  accept=".zip,.rar,.7zip,.pdf,.png,.jpg,.jpeg,.pptx,.docx,.xlsx"
                  show-size
                  label="Evidencia"
                  clearable
                  @change="changeInputFile($event)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
          <small></small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeDialogUploadFile">Cerrar</v-btn>
          <v-btn color="primary" text @click="confirmDialogUploadFile">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import MatrizService from '../services/MatrizService';
import moment from 'moment';
import Swal from 'sweetalert2';
import { decryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'MatrizLista',
  components: {},
  data: () => ({
    height: 180,
    opcionSolo: false,
    search: '',
    okrId: '',
    idokrFile: '',
    itemFile: '',
    idItemFile: '',
    evidencia: null,
    errorFile: false,
    dialog: false,
    semana: [],
    semanas: [],
    showLoading: false,
    matrizService: null,
    okrItems: [],
    headers: [
      {
        text: 'N°',
        value: 'numero',
        width: '100',
        class: 'header-items-fixed-seguimiento nth-child1 primary',
        fixed: true
      },
      {
        text: 'ACTIVIDAD',
        value: 'actividad',
        width: '200',
        class: 'header-items-fixed-seguimiento nth-child2 primary',
        fixed: true
      },
      {
        text: 'RESPONSABLE OKR',
        value: 'responsable',
        width: '250',
        class: 'header-items-fixed-seguimiento nth-child3 primary',
        fixed: true
      },
      {
        text: 'SEMANA',
        value: 'semana',
        width: '120',
        class: 'header-items-fixed-seguimiento nth-child4 primary',
        align: 'center',
        fixed: true
      },
      { text: 'ÁREA RESPONSABLE', value: 'subarea', width: '250', class: 'primary' },
      { text: 'estatus', value: 'realizado', width: '120', class: 'primary' },
      { text: 'FECHA ENTREGABLE', value: 'fechaentregable', width: '250', class: 'primary' },
      { text: 'ENTREGABLE', value: 'entregable', width: '250', class: 'primary' }
      /*   { text: 'COMENTARIO ENTREGABLE', value: 'realizadas', width: '250', class: 'primary' } */
    ],
    rules: [
      (value) => !value || value.size < 25000000 || 'El archivo debe de tener menos de 25 MB!',
      (value) => {
        if (value === null || value === undefined) return true;
        const res = value.name.slice(value.name.lastIndexOf('.') + 1, value.name.length).toLowerCase();
        if (
          res != 'exe' &&
          res != 'sql' &&
          res != 'jar' &&
          res != 'air' &&
          res != 'src' &&
          res != 'vb' &&
          res != 'bat' &&
          res != 'dll' &&
          res != 'com' &&
          res != 'bin' &&
          res != 'apk' &&
          res != 'app' &&
          res != 'cgi' &&
          res != 'wsf' &&
          res != 'gadget' &&
          res != 'log' &&
          res != 'css' &&
          res != 'sqlite' &&
          res != 'psd' &&
          res != 'ai' &&
          res != 'bak' &&
          res != 'js' &&
          res != 'cmd'
        )
          return true;
        return 'No se permite ese tipo de archivos.';
      }
    ],
    semanaSeleccionado: '',
    areas: [],
    itemsAreas: [],
    anio: new Date().getFullYear().toString(),
    listAnios: []
  }),
  computed: {
    checkAllAreas() {
      return (this.areas?.length ?? 0) === this.itemsAreas.length;
    },
    checkSomeAreas() {
      return (this.areas?.length ?? 0) > 0 && !this.checkAllAreas;
    },
    iconCheckboxAllAreas() {
      if (this.checkAllAreas) return 'mdi-close-box';
      if (this.checkSomeAreas) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    }
  },
  watch: {
    async opcionSolo() {
      await this.listarYCargarAreas();
    }
  },
  methods: {
    async listarYCargarAreas() {
      this.areas = [];
      await this.initialize();
      await this.cargarAreas();
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    async exportar({ idokr, correlativo, nombreentregable }) {
      this.showLoading = true;
      const token = localStorage.getItem('token');

      await this.matrizService.downloadResource(
        'descargarevidencia',
        {
          correlativo: correlativo,
          token: token,
          idokr: idokr
        },
        nombreentregable
      );
      this.showLoading = false;
    },
    async confirmDialogUploadFile() {
      const file = this.evidencia === null || this.evidencia === undefined ? null : this.evidencia;

      if (this.errorFile) {
        await this.alertDialog('warning', 'Tamaño o tipo de archivo no permitido');
        return;
      }

      const formData = new FormData();
      formData.append('idokr', this.idokrFile);
      formData.append('item', this.itemFile);
      formData.append('iditem', this.idItemFile);
      formData.append('file', file);
      formData.append('semanaactual', this.semanaSeleccionado);

      this.closeDialogUploadFile();

      this.showLoading = true;
      const res = await this.matrizService.post().execResource('subirevidencia', formData);
      await this.initialize();
      if (res.status) {
        await this.alertDialog('success', res.data);
      } else {
        await this.alertDialog('error', res.data);
      }
    },
    closeDialogUploadFile() {
      this.okrId = '';
      this.idokrFile = '';
      this.itemFile = '';
      this.evidencia = null;
      this.dialog = false;
    },
    openDialogFile({ idokr, correlativo, semana, item }) {
      console.log(correlativo);
      this.okrId = `${idokr} - SEMANA ${semana}`;
      this.semanaSeleccionado = semana;
      this.dialog = true;
      this.idokrFile = idokr;
      this.itemFile = correlativo;
      this.idItemFile = item;
    },
    changeInputFile($event) {
      const file = $event === null || $event === undefined ? null : $event;

      if (file === null) {
        return;
      }

      this.errorFile = false;

      if (file.size > 25000000) {
        this.errorFile = true;
      }

      const res = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (
        res == 'exe' ||
        res == 'sql' ||
        res == 'jar' ||
        res == 'air' ||
        res == 'src' ||
        res == 'vb' ||
        res == 'bat' ||
        res == 'dll' ||
        res == 'com' ||
        res == 'bin' ||
        res == 'apk' ||
        res == 'app' ||
        res == 'cgi' ||
        res == 'wsf' ||
        res == 'gadget' ||
        res == 'log' ||
        res == 'css' ||
        res == 'sqlite' ||
        res == 'psd' ||
        res == 'ai' ||
        res == 'bak' ||
        res == 'js' ||
        res == 'cmd'
      ) {
        this.errorFile = true;
      }
    },
    async exportarbase() {
      this.showLoading = true;
      const fecha1 = moment().format('YYYYMMDDHHMMSS');
      const nombreentregable = 'EXPORT-MATRIZSEGUIMIENTOOKR-' + fecha1 + '.xlsx';
      const token = localStorage.getItem('token');
      const emp = decryptAES(localStorage.getItem('emp'));
      await this.matrizService.downloadResource(
        'exportar',
        {
          idempresa: emp,
          anio: this.anio,
          semana: this.semana,
          subarea: this.areas.toString().replaceAll('&', '%26'),
          token: token,
          bandera: this.opcionSolo
        },
        nombreentregable
      );
      this.showLoading = false;
    },
    async initialize() {
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.matrizService.get().execResource('matriz', {
        idempresa: emp,
        anio: this.anio,
        semana: this.semana,
        subarea: this.areas.toString().replaceAll('&', '%26'),
        bandera: this.opcionSolo
      });
      if (res.data.length > 0) {
        this.okrItems = [];
        res.data.forEach((el, index) => {
          this.okrItems.push({
            numero: index + 1,
            actividad: el.descripcion,
            responsable: el.responsable,
            semana: el.semana,
            idokr: el.idokr,
            item: el.item,
            area: el.area,
            subarea: el.subarea,
            idsubarea: el.idsubarea,
            correlativo: el.correlativo,
            entregable: el.entregable === '' ? null : el.entregable,
            subio: el.subio,
            indicador: el.indicador,
            realizado: el.tipo,
            nombreentregable: el.nombrefichero,
            fechaentregable: el.fechaentregable != null ? moment(el.fechaentregable).format('DD-MM-YYYY HH:mm') : '',
            class: 'class-items-fixed-seguimiento'
          });
        });
        this.height = 400;
      } else {
        this.okrItems = [];
        this.height = 180;
      }

      this.showLoading = false;
    },
    async semanasItems() {
      this.showLoading = true;
      this.semana = [];
      this.semana.push(
        await this.matrizService.get().execResource('fecha', {
          fecha: this.anio == moment().year() ? moment().format('YYYY-MM-DD') : moment().year() + '-12-31'
        })
      );
      const res = await this.matrizService.get().execResource('fecha', {
        fecha: moment().year() + '-12-31'
      });
      for (let i = 1; i <= res; i++) {
        this.semanas.push(i);
      }
      this.showLoading = false;
    },
    selectAllAreas() {
      this.$nextTick(() => {
        if (this.checkAllAreas) {
          this.areas = [];
        } else {
          this.areas = this.itemsAreas.map((element) => {
            return element.value;
          });
        }
      });
    },
    async cargarAreas() {
      this.itemsAreas = [];
      this.okrItems.forEach((el) => {
        let exist = -1;
        exist = this.itemsAreas.findIndex((el2) => el2.value == el.idsubarea);
        if (exist == -1 && el.idsubarea != '') {
          this.itemsAreas.push({ value: el.idsubarea, text: el.subarea });
        }
      });
      this.itemsAreas.sort((a, b) => a.text.toLowerCase().localeCompare(b.text.toLowerCase()));
      this.areas = this.itemsAreas.map((el) => el.value);
    },
    async cargarAnios() {
      this.listAnios = [];
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.matrizService.get().execResource('cargarAnios', {
        idempresa: emp
      });
      this.listAnios = res.map((el) => {
        return { value: el.anio, text: el.anio };
      });
      this.showLoading = false;
    },
    async cambioAnio() {
      await this.semanasItems();
      await this.listarYCargarAreas();
    }
  },
  async mounted() {},
  async created() {
    this.showLoading = true;
    this.matrizService = this.$httpService(new MatrizService(), this);
    await this.cargarAnios();
    await this.semanasItems();
    await this.initialize();
    await this.cargarAreas();
    this.showLoading = false;
  }
};
</script>
<style lang="scss">
.altura > .v-data-table__wrapper {
  height: calc(100vh - 422px);
}
.header-items-fixed-seguimiento {
  position: sticky !important;
  position: -webkit-sticky !important;
}

.header-items-fixed-seguimiento.nth-child1 {
  top: 0;
  left: 0;
  z-index: 4 !important;
}
.header-items-fixed-seguimiento.nth-child2 {
  left: 100px;
  top: 0;
  z-index: 4 !important;
}
.header-items-fixed-seguimiento.nth-child3 {
  left: 300px;
  top: 0;
  z-index: 4 !important;
}
.header-items-fixed-seguimiento.nth-child4 {
  left: 550px;
  top: 0;
  z-index: 4 !important;
  border-right: thin solid rgba(250, 250, 250) !important;
}
.class-items-fixed-seguimiento td:nth-child(1) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 0;
  z-index: 3;
}
.class-items-fixed-seguimiento td:nth-child(2) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 100px;
  z-index: 3;
}
.class-items-fixed-seguimiento td:nth-child(3) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 300px;
  z-index: 3;
}
.class-items-fixed-seguimiento td:nth-child(4) {
  position: sticky !important;
  position: -webkit-sticky !important;
  left: 550px;
  z-index: 3;
}

.cell-d-none td:last-child {
  display: none;
}

.v-data-table--fixed-header > .v-data-table__wrapper > table > thead > tr > th {
  z-index: 3;
  color: white !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-seguimiento {
  background: white;
  color: rgba(0, 0, 0, 0.6);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr .header-items-fixed-seguimiento {
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.7);
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-seguimiento td:nth-child(1) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-seguimiento td:nth-child(1) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-seguimiento td:nth-child(2) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-seguimiento td:nth-child(2) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-seguimiento td:nth-child(3) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-seguimiento td:nth-child(3) {
  background: #1e1e1e;
  color: white;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-seguimiento td:nth-child(4) {
  background: white;
  color: rgba(0, 0, 0, 0.87);
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > .class-items-fixed-seguimiento td:nth-child(4) {
  background: #1e1e1e;
  color: white;
  border-right: thin solid rgba(255, 255, 255, 0.12);
}

.text-field-search-matriz.theme--light.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
}
.text-field-search-matriz.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
}

.text-field-search-matriz .v-input__control .v-input__slot .v-text-field__slot .theme--dark.v-label {
  color: rgba(0, 0, 0, 0.6);
}
</style>

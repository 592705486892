class MatrizService {
  constructor() {}
  resources = () => ({
    fecha: {
      uri: `/v1/semana`,
      method: ['get']
    },
    matriz: {
      uri: `/v1/okr/matrizseguimiento`,
      method: ['get']
    },
    exportar: {
      uri: `/v1/okr/exportarmatrizseguimiento`,
      method: ['get']
    },
    subirevidencia: {
      uri: `/v1/okr/subirfichero`,
      method: ['post'],
      headers: { 'Content-Type': 'multipart/form-data' }
    },
    descargarevidencia: {
      uri: `/v1/okr/downloadfile`,
      method: ['get']
    },
    cargarAnios: {
      uri: `/v1/okr/listanios`,
      method: ['get']
    }
  });
}

export default MatrizService;
